import CheckIcon from "../images/icon/check.svg";
import Utm from "../components/_global/utm";

// images
import TopBackground from "../images/europeHeader.jpg";
import image1 from "../images/europe-results-1.png";
import image2 from "../images/centered-results-2.png";
import image3 from "../images/centered-results-3.png";
import image4 from "../images/centered-results-4.png";
import partners from "../images/centered-partners.jpg";

export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t("europe.europeTopTitle"),
        colorClass: "colorLight",
      },
      subtitle: {
        text: t("europe.europeTopSubTitle"),
        colorClass: "colorLight",
      },
      cta: {
        text: t("centeredPage.ctaText"),
        url:process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
        type: "mainColor",
        extraClass: "bigWidth",
      },
      bottomDiagonal: true,
      opacity: true,
      full: true,
      image: {
        src: TopBackground,
        alt: t("europe.imgAlt"),
        bottomPosition: true,
        bigHeight: true,
      },
    },
    image1: {
      src: image1,
      alt: t("centeredPage.suppliersAlt"),
    },
    bullet1: {
      title: {
        text: t("centeredPage.newGarmentTitle"),
        colorClass: "colorDark",
        extraClass: "text-center",
        heading:"h1"
      },
      icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt") },
      separator: false,
      item: {
        text: [t("centeredPage.access"), t("centeredPage.select"), t("centeredPage.identify"), t("centeredPage.filter")],
        colorClass: "colorDark",
      },
      cta: {
        text: t("centeredPage.startNow"),
        url:process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
        type: "mainColor",
        extraClass: "bigWidth",
      },
    },
    image2: {
      src: image2,
      alt: t("centeredPage.findSuppliersAlt"),
    },
    bullet2: {
      title: {
        text: t("centeredPage.quotesTitle"),
        colorClass: "colorDark",
          extraClass: "text-center",
          heading:"h1"
      },
      icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt") },
      separator: false,
      item: {
        text: [t("centeredPage.rfq"), t("centeredPage.receive"), t("centeredPage.requests"), t("centeredPage.targeted")],
        colorClass: "colorDark",
      },
      cta: {
        text: t("centeredPage.startNow"),
        url:process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
        type: "mainColor",
        extraClass: "bigWidth",
      },
    },
    image3: {
      src: image3,
      alt: t("centeredPage.requestQuotationAlt"),
    },
    bullet3: {
      title: {
        text: t("centeredPage.manageTitle"),
        colorClass: "colorDark",
        extraClass: "text-center",
        heading:"h1"
      },
      icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt") },
      separator: false,
      item: {
        text: [t("centeredPage.manageSocial"), t("centeredPage.saveTime"), t("centeredPage.understandingCompliance"), t("centeredPage.keepTrack")],
        colorClass: "colorDark",
      },
      cta: {
        text: t("centeredPage.ctaText"),
        url:process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
        type: "mainColor",
        extraClass: "bigWidth",
      },
    },
    image4: {
      src: image4,
      alt: t("centeredPage.virtualShowroomAlt"),
    },
    aboutUs: {
      title: {
        text: t("centeredPage.aboutUs"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("centeredPage.aboutUsText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: false,
    },
    partnersTitle: {
      title: {
        text: t("centeredPage.partnersTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
    },
    partners: {
      src: partners,
      alt: t("centeredPage.partnersAlt"),
    },
    cta: {
      text: t("europe.ctaTextButton"),
      url:process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
      type: "mainColor",
      extraClass: "bigWidth",
    },
  };
};
